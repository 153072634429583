import axios from 'axios';
import { getErrorDetails, getResponseData, prepareRequestUrl } from 'state/actions/apiHelpers';
import { addNotification } from 'common/redux/common.actions';
import { errorHandler } from 'common/utils/response.handler';
import { formatDate4 } from '../../common/helpers';

export const ADMIN_ACTIONS = {
  USER_REQUEST: 'USER_REQUEST',
  ORGANIZATION_REQUEST: 'ORGANIZATION_REQUEST',
  WORKSPACE_REQUEST: 'WORKSPACE_REQUEST',
  USER_DETAIL_REQUEST: 'USER_DETAIL_REQUEST',
  USER_DETAIL_UPDATE_REQUEST: 'USER_DETAIL_UPDATE_REQUEST',
  USER_ORGANIZATION_REQUEST: 'USER_ORGANIZATION_REQUEST',
  ORGANIZATION_DETAIL_UPDATE_REQUEST: 'ORGANIZATION_DETAIL_UPDATE_REQUEST',
  ORGANIZATION_WORKSPACE_CREATE_REQUEST: 'ORGANIZATION_WORKSPACE_CREATE_REQUEST',
  ORGANIZATION_LIMIT_REQUEST: 'ORGANIZATION_LIMIT_REQUEST',
  ORGANIZATION_LIMIT_UPDATE_REQUEST: 'ORGANIZATION_LIMIT_UPDATE_REQUEST',
  ORGANIZATION_SERVICE_UPDATE_REQUEST: 'ORGANIZATION_SERVICE_UPDATE_REQUEST',
  ORGANIZATION_LIMIT_DEFAULT_REQUEST: 'ORGANIZATION_LIMIT_DEFAULT_REQUEST',
  WORKSPACE_DETAIL_REQUEST: 'WORKSPACE_DETAIL_REQUEST',
  WORKSPACE_ACTIVITY_FILTER: 'WORKSPACE_ACTIVITY_FILTER',
  RESET_WORKSPACE_ACTIVITY: 'RESET_WORKSPACE_ACTIVITY',
  WORKSPACE_DETAIL_UPDATE_REQUEST: 'WORKSPACE_DETAIL_UPDATE_REQUEST',
  SERVER_REQUEST: 'SERVER_REQUEST',
  SERVER_DETAIL_REQUEST: 'SERVER_DETAIL_REQUEST',
  SERVER_DETAIL_UPDATE_REQUEST: 'SERVER_DETAIL_UPDATE_REQUEST',
  SERVER_ROLE_REQUEST: 'SERVER_ROLE_REQUEST',
  ZONE_REQUEST: 'ZONE_REQUEST',
  LIMIT_REQUEST: 'LIMIT_REQUEST',
  LIMIT_UPDATE_REQUEST: 'LIMIT_UPDATE_REQUEST',
  SERVICES_REQUEST: 'SERVICES_REQUEST',
  SERVICES_DETAIL_UPDATE_REQUEST: 'SERVICES_DETAIL_UPDATE_REQUEST',
  SERVICE_HEALTH_CHECK_REQUEST: 'SERVICE_HEALTH_CHECK_REQUEST',
  GET_SERVICE: 'GET_SERVICE',
  CREATE_SERVICE: 'CREATE_SERVICE',
  SERVICE_LOG_REQUEST: 'SERVICE_LOG_REQUEST',
  GET_WORKSPACE_USERS: 'GET_WORKSPACE_USERS',
  INVITE_WORKSPACE_USERS: 'INVITE_WORKSPACE_USERS',
  DELETE_WORKSPACE_USERS: 'DELETE_WORKSPACE_USERS',
  WORKSPACE_TRANSFER_REQUEST: 'WORKSPACE_TRANSFER_REQUEST',
  PREPARE_WORKSPACE_TRANSFER_REQUEST: 'PREPARE_WORKSPACE_TRANSFER_REQUEST',
  CANCEL_WORKSPACE_TRANSFER_REQUEST: 'CANCEL_WORKSPACE_TRANSFER_REQUEST',
  START_WORKSPACE_TRANSFER_REQUEST: 'START_WORKSPACE_TRANSFER_REQUEST',
  MAKE_ADMIN_SEND_OTP_REQUEST: 'MAKE_ADMIN_SEND_OTP_REQUEST',
  MAKE_ADMIN_VERIFY_OTP_REQUEST: 'MAKE_ADMIN_VERIFY_OTP_REQUEST',
};

export const fetchFilteredUserSuccess = (data) => ({
  type: ADMIN_ACTIONS.USER_REQUEST,
  payload: data,
});

export const fetchFilteredUsers = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/system/users/filter')}`, data)
    .then((response) => {
      dispatch(fetchFilteredUserSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const fetchFilteredOrganizationSuccess = (data) => ({
  type: ADMIN_ACTIONS.ORGANIZATION_REQUEST,
  payload: data,
});

export const fetchFilteredOrganizations = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/system/organizations/filter')}`, data)
    .then((response) => {
      dispatch(fetchFilteredOrganizationSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const fetchFilteredWorkspaceSuccess = (data) => ({
  type: ADMIN_ACTIONS.WORKSPACE_REQUEST,
  payload: data,
});

export const fetchFilteredWorkspaces = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/system/workspaces/filter')}`, data)
    .then((response) => {
      dispatch(fetchFilteredWorkspaceSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const userDetaileSuccess = (data) => ({
  type: ADMIN_ACTIONS.USER_DETAIL_REQUEST,
  payload: data,
});

export const getUserDetail = (id) => (dispatch) => (
  axios.get(`${prepareRequestUrl(`admin/system/users/${id}`)}`, null)
    .then((response) => {
      dispatch(userDetaileSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const organizationWorkspaceCreateSuccess = (data) => ({
  type: ADMIN_ACTIONS.ORGANIZATION_WORKSPACE_CREATE_REQUEST,
  payload: data,
});

export const organizationWorkspaceCreate = (id, data) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/users/${id}/organization/workspace`)}`, data)
    .then((response) => {
      dispatch(organizationWorkspaceCreateSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'admin_user_organization_workspace_create_error'));
      throw (error);
    })
);

export const organizationLimitSuccess = (data) => ({
  type: ADMIN_ACTIONS.ORGANIZATION_LIMIT_REQUEST,
  payload: data,
});

export const getOrganizationLimit = (id) => (dispatch) => (
  axios.get(`${prepareRequestUrl(`admin/system/users/${id}/organization/limits`)}`, null)
    .then((response) => {
      dispatch(organizationLimitSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const updateOrganizationLimitSuccess = (data) => ({
  type: ADMIN_ACTIONS.ORGANIZATION_LIMIT_UPDATE_REQUEST,
  payload: data,
});

export const updateOrganizationLimit = (id, organizationLimitId, data) => (dispatch) => (
  axios.patch(`${prepareRequestUrl(`admin/system/users/${id}/organization/limits/${organizationLimitId}`)}`, data)
    .then((response) => {
      dispatch(updateOrganizationLimitSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const updateOrganizationServiceSuccess = (data) => ({
  type: ADMIN_ACTIONS.ORGANIZATION_SERVICE_UPDATE_REQUEST,
  payload: data,
});

export const updateOrganizationService = (id, data) => (dispatch) => (
  axios.put(`${prepareRequestUrl(`admin/system/users/${id}/organization/service`)}`, data)
    .then((response) => {
      dispatch(updateOrganizationServiceSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const setDefaultOrganizationLimitSuccess = (data) => ({
  type: ADMIN_ACTIONS.ORGANIZATION_LIMIT_DEFAULT_REQUEST,
  payload: data,
});

export const setDefaultOrganizationLimit = (id, organizationLimitId) => (dispatch) => (
  axios.put(`${prepareRequestUrl(`admin/system/users/${id}/organization/limits/${organizationLimitId}`)}`, null)
    .then((response) => {
      dispatch(setDefaultOrganizationLimitSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const userDetailUpdateSuccess = (data) => ({
  type: ADMIN_ACTIONS.USER_DETAIL_UPDATE_REQUEST,
  payload: data,
});

export const updateUserDetail = (id, data) => (dispatch) => (
  axios.patch(`${prepareRequestUrl(`admin/system/users/${id}`)}`, data)
    .then((response) => {
      dispatch(addNotification(null, 'admin__user__updated'));
      dispatch(userDetailUpdateSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_user_update_error');
      throw (error);
    })
);

export const informUser = (id) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/users/${id}/inform`)}`)
    .then(() => {
      dispatch(addNotification(null, 'admin__user__inform'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_user_update_error');
      throw (error);
    })
);

export const organizationDetaileSuccess = (data) => ({
  type: ADMIN_ACTIONS.USER_ORGANIZATION_REQUEST,
  payload: data,
});

export const getOrganizationDetail = (id) => (dispatch) => (
  axios.get(`${prepareRequestUrl(`admin/system/users/${id}/organization`)}`, null)
    .then((response) => {
      dispatch(organizationDetaileSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const organizationDetailUpdateSuccess = (data) => ({
  type: ADMIN_ACTIONS.ORGANIZATION_DETAIL_UPDATE_REQUEST,
  payload: data,
});

export const updateOrganizationDetail = (id, data) => (dispatch) => (
  axios.patch(`${prepareRequestUrl(`admin/system/users/${id}/organization`)}`, data)
    .then((response) => {
      dispatch(addNotification(null, 'admin_organization_updated'));
      dispatch(organizationDetailUpdateSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_organization_update_error');
      throw (error);
    })
);

export const workspaceDetaileSuccess = (data) => ({
  type: ADMIN_ACTIONS.WORKSPACE_DETAIL_REQUEST,
  payload: data,
});

export const getWorkspaceDetail = (id) => (dispatch) => (
  axios.get(`${prepareRequestUrl(`admin/system/workspaces/${id}`)}`, null)
    .then((response) => {
      dispatch(workspaceDetaileSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const clearWorkspaceActivity = () => ({ type: ADMIN_ACTIONS.RESET_WORKSPACE_ACTIVITY });

export const workspaceActivityFilterSuccess = (payload) => ({
  type: ADMIN_ACTIONS.WORKSPACE_ACTIVITY_FILTER,
  payload,
});

export const filterWorkspaceActivity = (data) => (dispatch, getState) => (
  axios.post(prepareRequestUrl(`admin/system/workspaces/${data.id}/activity/filter`), {
    date: {
      interval: data && (data.interval === 'custom' ? null : data.interval),
      start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
      end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
    },
    user_id: data && data.userID !== 'all' ? data.userID : null,
    next: data.currentNumber > 1 ? getState().workspaces.nextActivity : null,
  })
    .then((response) => dispatch(workspaceActivityFilterSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const workspaceDetailUpdateSuccess = (data) => ({
  type: ADMIN_ACTIONS.WORKSPACE_DETAIL_UPDATE_REQUEST,
  payload: data,
});

export const updateWorkspaceDetail = (id, data) => (dispatch) => (
  axios.patch(`${prepareRequestUrl(`admin/system/workspaces/${id}`)}`, data)
    .then((response) => {
      dispatch(addNotification(null, 'admin_workspace_updated'));
      dispatch(workspaceDetailUpdateSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_workspace_update_error');
      throw (error);
    })
);

export const adminServerSuccess = (data) => ({
  type: ADMIN_ACTIONS.SERVER_REQUEST,
  payload: data,
});

export const getAdminServer = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/cdn/servers/filter')}`, data)
    .then((response) => {
      dispatch(adminServerSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const deleteAdminServer = (id) => (dispatch) => (
  axios.delete(`${prepareRequestUrl(`admin/cdn/servers/${id}`)}`, null)
    .then(() => {
      dispatch(addNotification(null, 'admin_server_deleted'));
    })
    .catch((error) => {
      throw (error);
    })
);

export const serverDetailSuccess = (data) => ({
  type: ADMIN_ACTIONS.SERVER_DETAIL_REQUEST,
  payload: data,
});

export const getServerDetail = (id) => (dispatch) => (
  axios.get(`${prepareRequestUrl(`admin/cdn/servers/${id}`)}`, null)
    .then((response) => {
      dispatch(serverDetailSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const ServerDetailUpdateSuccess = (data) => ({
  type: ADMIN_ACTIONS.SERVER_DETAIL_UPDATE_REQUEST,
  payload: data,
});

export const updateServerDetail = (id, data) => (dispatch) => (
  axios.patch(`${prepareRequestUrl(`admin/cdn/servers/${id}`)}`, data)
    .then((response) => {
      dispatch(addNotification(null, 'admin_server_updated'));
      dispatch(ServerDetailUpdateSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_server_update_error');
      throw (error);
    })
);

export const createServer = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/cdn/servers')}`, data)
    .then(() => {
      dispatch(addNotification(null, 'admin_server_created'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_server_created_error');
      throw (error);
    })
);

export const adminServerRoleSuccess = (data) => ({
  type: ADMIN_ACTIONS.SERVER_ROLE_REQUEST,
  payload: data,
});

export const getServerRoles = () => (dispatch) => (
  axios.get(`${prepareRequestUrl('admin/cdn/server-roles')}`, null)
    .then((response) => {
      dispatch(adminServerRoleSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const adminZoneSuccess = (data) => ({
  type: ADMIN_ACTIONS.ZONE_REQUEST,
  payload: data,
});

export const getAdminZone = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/cdn/zones/filter')}`, data)
    .then((response) => {
      dispatch(adminZoneSuccess(getResponseData(response)));
      return response;
    })
    .catch((error) => {
      throw (error);
    })
);

export const adminServiceLogSuccess = (data) => ({
  type: ADMIN_ACTIONS.SERVICE_LOG_REQUEST,
  payload: data,
});

export const getAdminServiceLog = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/system/service/logs/filter')}`, data)
    .then((response) => {
      dispatch(adminServiceLogSuccess(getResponseData(response)));
      return response;
    })
    .catch((error) => {
      throw (error);
    })
);

export const adminServicesSuccess = (data) => ({
  type: ADMIN_ACTIONS.SERVICES_REQUEST,
  payload: data,
});

export const getAdminServices = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/system/service/filter')}`, data)
    .then((response) => {
      dispatch(adminServicesSuccess(getResponseData(response)));
      return response;
    })
    .catch((error) => {
      throw (error);
    })
);

export const adminServiceDetailUpdateSuccess = (data) => ({
  type: ADMIN_ACTIONS.SERVICES_DETAIL_UPDATE_REQUEST,
  payload: data,
});

export const updateAdminServiceDetail = (id, data) => (dispatch) => (
  axios.put(`${prepareRequestUrl(`admin/system/service/${id}/detail`)}`, data)
    .then((response) => {
      dispatch(adminServiceDetailUpdateSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'update-admin-microservice-detail'));
      return response;
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'admin_service_detail_upsert_error'));
      throw (error);
    })
);

export const adminGetServiceSuccess = (data) => ({
  type: ADMIN_ACTIONS.GET_SERVICE,
  payload: data,
});

export const getAdminService = (id) => (dispatch) => (
  axios.get(`${prepareRequestUrl(`admin/system/service/${id}`)}`)
    .then((response) => {
      dispatch(adminGetServiceSuccess(getResponseData(response)));
      return response;
    })
    .catch((error) => {
      throw (error);
    })
);

export const adminServiceHealthCheckSuccess = (data) => ({
  type: ADMIN_ACTIONS.SERVICE_HEALTH_CHECK_REQUEST,
  payload: data,
});

export const getAdminServiceHealthCheck = (id, data) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/service/${id}/health-check/filter`)}`, data)
    .then((response) => {
      dispatch(adminServiceHealthCheckSuccess(getResponseData(response)));
      return response;
    })
    .catch((error) => {
      throw (error);
    })
);

export const adminCreateServiceSuccess = (data) => ({
  type: ADMIN_ACTIONS.CREATE_SERVICE,
  payload: data,
});

export const createAdminService = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/system/service')}`, data)
    .then((response) => {
      dispatch(adminCreateServiceSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'create-admin-microservice-success'));
      return response;
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_service_created_error');
      throw (error);
    })
);

export const getWorkspaceUsersSuccess = (data) => ({
  type: ADMIN_ACTIONS.GET_WORKSPACE_USERS,
  payload: data,
});

export const getWorkspaceUsers = (id, data) => (dispatch) => (
  axios.get(`${prepareRequestUrl(`admin/system/workspaces/${id}/users`)}`, {
    params: {
      page: data.currentPageNumber,
    },
  })
    .then((response) => {
      dispatch(getWorkspaceUsersSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const deleteWorkspaceUserSuccess = (data) => ({
  type: ADMIN_ACTIONS.DELETE_WORKSPACE_USERS,
  payload: data,
});

export const deleteWorkspaceUser = (id, userId) => (dispatch) => (
  axios.delete(`${prepareRequestUrl(`admin/system/workspaces/${id}/users/${userId}`)}`, null)
    .then((response) => {
      dispatch(deleteWorkspaceUserSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const inviteWorkspaceUserSuccess = (data) => ({
  type: ADMIN_ACTIONS.INVITE_WORKSPACE_USERS,
  payload: data,
});

export const inviteWorkspaceUser = (id, data) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/workspaces/${id}/users`)}`, data)
    .then((response) => {
      dispatch(inviteWorkspaceUserSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'invite-user-success'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_invite_user_error');
      throw (error);
    })
);

export const fetchFilteredWorkspaceTransferSuccess = (data) => ({
  type: ADMIN_ACTIONS.WORKSPACE_TRANSFER_REQUEST,
  payload: data,
});

export const fetchFilteredWorkspaceTransfers = (id, data) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/workspaces/${id}/transfer/filter`)}`, data)
    .then((response) => {
      dispatch(fetchFilteredWorkspaceTransferSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const prepareWorkspaceTransferSuccess = (data) => ({
  type: ADMIN_ACTIONS.PREPARE_WORKSPACE_TRANSFER_REQUEST,
  payload: data,
});

export const prepareWorkspaceTransfers = (id, data) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/workspaces/${id}/transfer/prepare`)}`, data)
    .then((response) => {
      dispatch(prepareWorkspaceTransferSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_ws_transfer_prepare_error');
      throw (error);
    })
);

export const cancelWorkspaceTransferSuccess = (data) => ({
  type: ADMIN_ACTIONS.CANCEL_WORKSPACE_TRANSFER_REQUEST,
  payload: data,
});

export const cancelWorkspaceTransfers = (id, transferId) => (dispatch) => (
  axios.delete(`${prepareRequestUrl(`admin/system/workspaces/${id}/transfer/${transferId}/cancel`)}`)
    .then((response) => {
      dispatch(cancelWorkspaceTransferSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'admin_ws_transfer_cancel'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_ws_transfer_cancel_error');
      throw (error);
    })
);

export const startWorkspaceTransferSuccess = (data) => ({
  type: ADMIN_ACTIONS.START_WORKSPACE_TRANSFER_REQUEST,
  payload: data,
});

export const startWorkspaceTransfers = (id, transferId) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/workspaces/${id}/transfer/${transferId}/start`)}`)
    .then((response) => {
      dispatch(startWorkspaceTransferSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'admin_ws_transfer_start'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'admin_ws_transfer_start_error');
      throw (error);
    })
);

export const adminLimitSuccess = (data) => ({
  type: ADMIN_ACTIONS.LIMIT_REQUEST,
  payload: data,
});

export const getAdminLimit = (data) => (dispatch) => (
  axios.post(`${prepareRequestUrl('admin/system/limits/filter')}`, data)
    .then((response) => {
      dispatch(adminLimitSuccess(getResponseData(response)));
      return response;
    })
    .catch((error) => {
      throw (error);
    })
);

export const adminLimitUpdateSuccess = (data) => ({
  type: ADMIN_ACTIONS.LIMIT_UPDATE_REQUEST,
  payload: data,
});

export const updateAdminLimit = (id, data) => (dispatch) => (
  axios.patch(`${prepareRequestUrl(`admin/system/limits/${id}`)}`, data)
    .then((response) => {
      dispatch(adminLimitUpdateSuccess(getResponseData(response)));
      return response;
    })
    .catch((error) => {
      throw (error);
    })
);

export const makeAdminSendOTPSuccess = (data) => ({
  type: ADMIN_ACTIONS.MAKE_ADMIN_SEND_OTP_REQUEST,
  payload: data,
});

export const makeAdminSendOTP = (id) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/users/${id}/type/admin`)}`)
    .then((response) => {
      dispatch(makeAdminSendOTPSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'make_admin_otp_send'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'make_admin_otp_send_error');
      throw (error);
    })
);

export const makeAdminVerifyOTPSuccess = (data) => ({
  type: ADMIN_ACTIONS.MAKE_ADMIN_VERIFY_OTP_REQUEST,
  payload: data,
});

export const makeAdminVerifyOTP = (id, data) => (dispatch) => (
  axios.post(`${prepareRequestUrl(`admin/system/users/${id}/type/admin/verify`)}`, data)
    .then((response) => {
      dispatch(makeAdminVerifyOTPSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'make_admin_otp_verify'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'make_admin_otp_verify_error');
      throw (error);
    })
);
